
import { PcServer } from '@/api/maintain/pc-server'
import { TeamResourcePcServer } from '@/api/maintain/resource-management/team-resource-pc-server'
import { showModal } from '@/libs/modal-helper'
import modal from './pc-server-selector-modal.vue'

/**
  * 服务器列表选择器对话框
  * @returns
  */
export default function showPcServerSelectorModal (pcServerList: PcServer[]): Promise<TeamResourcePcServer[]> {
  return new Promise<TeamResourcePcServer[]>((resolve, reject) => {
    showModal<TeamResourcePcServer[]>(modal, {
      props: {
        pcServerList
      }
    }, true, (res: TeamResourcePcServer[]) => {
      resolve(res)
      return new Promise<void>((resolve, reject) => resolve())
    }, () => {
      reject(new Error('用户取消'))
    })
  })
}
