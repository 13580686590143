import DefaultApiService, { BaseEntity } from '@/libs/api-request'
import request, { PagerQueryBean, PageDataApiResult, ApiResult } from '@/libs/http-request'

/** 成员类型 */
export enum ProjectMemberType {
  Individual,
  Group
}

/** 资源对应用用户或用户组 */
export interface TeamResourceGroupAndUser extends BaseEntity {
  /** 名称 */
  name: string;
  /** 团队资源id */
  teamResourceId: number;
  /** 用户或组 */
  userOrGroupId: number;
  /** 成员类型 */
  memberType: ProjectMemberType;
  /** 是否可以添加应用 */
  canAddApplication?: boolean;
  /** 是否可以进行操作 */
  canOperation?: boolean;
  /** 是否可以进行查看 */
  canView?: boolean;
}

export interface TeamResourceGroupAndUserView extends TeamResourceGroupAndUser {
  /** 用户在团队的名称 */
  nameAtTeam?: string;
  /** 用户登录名 */
  userLoginName?: string;
  /** 用户组名称 */
  userGroupName?: string;
  /** 用户是否禁用(用户组无效) */
  disable?: boolean;

  memberTypeName?: string;

  userDisplayName?: string;
}

/** 资源对应用用户或用户组Api接口 */
class TeamResourceGroupAndUserApi extends DefaultApiService<TeamResourceGroupAndUser> {
  constructor () {
    super('/api/maintain/team-resource-group-and-user')
  }

  queryView (query: PagerQueryBean): Promise<PageDataApiResult<TeamResourceGroupAndUserView>> {
    return this.queryByQueryBean('query-view', query)
  }

  /**
   * 通过记录id修改权限
   * @param id
   * @param rightName
   * @param value
   * @returns
   */
  setRightById (id: number, rightName: string, value: boolean) {
    return request<ApiResult>({
      url: `${this.baseUrlPath}set-right/${id}/${rightName}/${value}`,
      method: 'GET'
    })
  }
}

export default new TeamResourceGroupAndUserApi()
