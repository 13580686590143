
import { Prop, Component, Vue } from 'vue-property-decorator'
import { BaseModal } from '@/libs/modal-helper'
import { PropType } from 'vue'
import { TeamGroup } from '@/api/team-group'
import { TeamUse } from '@/api/team-user'
import TeamResourceGroupAndUserApi, { TeamResourceGroupAndUser, ProjectMemberType } from '@/api/maintain/resource-management/team-resource-group-and-user'
import _ from 'lodash'

@Component({
  name: 'AddUserOrGroup'
})
export default class AddUserOrGroup extends Vue implements BaseModal {
  /** 所有的用户信息 */
  @Prop({
    type: Array as PropType<TeamUse[]>,
    required: true
  })
  userList!: TeamUse[]

  /** 所有的用户组信息 */
  @Prop({
    type: Array as PropType<TeamGroup[]>,
    required: true
  })
  groupList!: TeamGroup[]

  /** 资源id */
  @Prop({
    type: Number,
    required: true
  })
  resourceId!: number

  /** 查看/修改时传的数据 */
  @Prop({
    type: Object as PropType<TeamResourceGroupAndUser>,
    default: () => { return {} }
  })
  oriData!: TeamResourceGroupAndUser

  // 显示状态
  visiable = false
  // 用户id
  userId: number | null = null
  // 用户组id
  groupId: number | null = null
  // 选中的类型
  type = '用户组'
  // 允许的操作
  permission = []
  // 当前数据
  data: TeamResourceGroupAndUser = {
    name: '',
    teamResourceId: 0,
    userOrGroupId: 0,
    memberType: 0
  }

  changeCanOperation (val: boolean) {
    if (val) this.$set(this.data, 'canView', true)
  }

  show (): void {
    if (this.oriData && this.oriData.id) {
      this.data = _.cloneDeep(this.oriData)
      if (this.data.memberType === ProjectMemberType.Group) {
        this.type = '用户组'
        this.groupId = this.data.userOrGroupId
      } else {
        this.type = '用户'
        this.userId = this.data.userOrGroupId
      }
    } else {
      this.data = {
        name: '',
        teamResourceId: 0,
        userOrGroupId: 0,
        memberType: 0
      }
    }
    this.visiable = true
  }

  close (): void {
    this.visiable = false
  }

  doSave (): void {
    // 判断用户类型
    if (this.type === '用户组') {
      this.data.memberType = ProjectMemberType.Group
      this.data.userOrGroupId = this.groupId || 0
    } else {
      this.data.memberType = ProjectMemberType.Individual
      this.data.userOrGroupId = this.userId || 0
    }
    // 添加资源id
    this.data.teamResourceId = this.resourceId

    // 如果存在原数据 说明是修改
    if (this.oriData && this.oriData.id) {
      TeamResourceGroupAndUserApi.updateItem(this.data)
        .then(() => {
          this.$emit('onOk')
        })
        .catch((err) => {
          this.$Message.error('修改失败: ' + err)
        })
    } else {
      // 不存在原数据 说明是新增
      TeamResourceGroupAndUserApi.insertItem(this.data)
        .then(() => {
          this.$emit('onOk')
        })
        .catch((err) => {
          this.$Message.error('新增失败: ' + err)
        })
    }
  }

  doCancel (): void {
    this.$emit('onCancel')
  }
}
