
import { Application } from '@/api/maintain/application'
import { TeamResourceApp } from '@/api/maintain/resource-management/team-resource-app'
import { showModal } from '@/libs/modal-helper'
import modal from './app-selector-modal.vue'

/**
  * 应用列表选择器对话框
  * @returns
  */
export default function showAppSelectorModal (appList: Application[]): Promise<TeamResourceApp[]> {
  return new Promise<TeamResourceApp[]>((resolve, reject) => {
    showModal<TeamResourceApp[]>(modal, {
      props: {
        appList
      }
    }, true, (res: TeamResourceApp[]) => {
      resolve(res)
      return new Promise<void>((resolve, reject) => resolve())
    }, () => {
      reject(new Error('用户取消'))
    })
  })
}
