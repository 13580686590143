
import { Prop, Vue, Component } from 'vue-property-decorator'
import { BaseModal } from '@/libs/modal-helper'
import { PcServer } from '@/api/maintain/pc-server'
import { PropType } from 'vue'

/** 服务器选择器组件 */
@Component({
  name: 'PcServerSelectorModal',
  components: { }
})
export default class PcServerSelectorModal extends Vue implements BaseModal {
  @Prop({
    type: Array as PropType<PcServer[]>,
    required: true
  })
  pcServerList!: PcServer[]

  // 显示状态
  visiable = false
  pcServer: number[] = []

  show (): void {
    this.visiable = true
  }

  close (): void {
    this.visiable = false
  }

  doSave (): void {
    this.$emit('onOk',
      this.pcServerList
        .filter(item => this.pcServer.findIndex((e) => e === item.id) >= 0)
        .map(item => {
          return {
            teamResourceId: 0,
            pcServerId: item.id!,
            name: item.name || '',
            ipAddress: item.ipAddress || ''
          }
        })
    )
  }

  doCancel (): void {
    this.$emit('onCancel')
  }
}
