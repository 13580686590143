import DefaultApiService, { BaseHasCreatorAndTimeEntity } from '@/libs/api-request'

/** 资源应用项 */
export interface TeamResourceApp extends BaseHasCreatorAndTimeEntity {
  /** 团队资源id */
  teamResourceId: number;
  /** 应用id */
  applicationId: number;
  /** 应用名称 */
  name: string;
  /** 应用标题 */
  title: string;
}

/** 资源应用项Api接口 */
class TeamResourceAppApi extends DefaultApiService<TeamResourceApp> {
  constructor () {
    super('/api/maintain/team-resource-app')
  }

  /**
   * 根据resourceId查询服务器
   * @param id
   * @returns
   */
  public getItemByResourceId (id: number) {
    return this.requestList(`getItemByResourceId/${id}`, undefined, 'GET')
  }

  /**
   * 插入多条数据
   * @param data
   * @returns
   */
  public insertItems (data: TeamResourceApp[]) {
    return this.request('insertItems', data, 'PUT')
  }

  /**
   * 根据应用id删除多条记录
   * @param resourceId
   * @param appIds
   * @returns
   */
  public deleteByApplicationId (resourceId: number, appIds: number[]) {
    return this.request(`application/${resourceId}`, appIds, 'DELETE')
  }
}

export default new TeamResourceAppApi()
