
import { Component } from 'vue-property-decorator'
import DataFormPageView from '@/frame/share/data-form-page.vue'
import StateDataFormPage from '@/frame/share/state-form-page'
import CollapsePanel from '@/components/collapse-panle.vue'
import StateFormItem from '@/components/state-formitem.vue'
import StateButton from '@/components/state-formbutton.vue'
import { Form } from 'view-design'
import TeamResourceApi, {
  TeamResource
} from '@/api/maintain/resource-management/team-resource'
import StateEditor from '@/components/state-editor.vue'
import PermissionConfigTable from './permission-config-table.vue'
import { getterUserInfo } from '@/store'
import showPcServerSelectorModal from './pc-server-selector-modal'
import showAppSelectorModal from './app-selector-modal'
import PcServerApi, { PcServer } from '@/api/maintain/pc-server'
import ApplicationApi, { Application } from '@/api/maintain/application'
import TeamResourcePcServerApi, {
  TeamResourcePcServer
} from '@/api/maintain/resource-management/team-resource-pc-server'
import TeamResourceAppApi, {
  TeamResourceApp
} from '@/api/maintain/resource-management/team-resource-app'
import moment from 'moment'

@Component({
  name: 'ResourceManagementDataPage',
  components: {
    DataFormPageView,
    CollapsePanel,
    StateFormItem,
    StateButton,
    StateEditor,
    PermissionConfigTable
  }
})
export default class ResourceManagementDataPage extends StateDataFormPage<
  TeamResource,
  any
> {
  protected serverState = '';
  /** 当前表单数据 */
  formData: TeamResource = {} as TeamResource;
  /** 所有服务器列表 */
  pcServerList: PcServer[] = [];
  /** 所有应用列表 */
  appList: Application[] = [];
  /** 当前表单的服务器数据列表 */
  curPcServerList: TeamResourcePcServer[] = [];
  /** 当前表单的应用数据列表 */
  curAppList: TeamResourceApp[] = [];

  firstPcServerId = 0;

  /**
   * 是否开通企业用户
   */
  get opening () {
    const user = getterUserInfo()
    return (
      user.operaExpirationTime && moment(user.operaExpirationTime).isAfter(moment.now())
    )
  }

  rules = {
    name: [{ required: true, message: '资源名称不允许为空', trigger: 'blur' }]
  };

  created () {
    this.loadPcServerList()
    this.loadAppList()
    this.initFromState({})
  }

  /** 加载所有的服务器列表 */
  loadPcServerList () {
    PcServerApi.query({ page: 1, pageSize: 10000, sorts: [] })
      .then((res) => {
        this.pcServerList = res.data?.items || []
        if (!this.opening) {
          PcServerApi.getFirstPcServerId().then((res) => {
            this.firstPcServerId = res.data || 0
            this.pcServerList = this.pcServerList.filter(
              (e) => e.id === this.firstPcServerId
            )
          })
        }
      })
      .catch((err) => {
        this.$Notice.error({
          title: '错误',
          desc: '服务器列表加载失败:' + err.message
        })
      })
  }

  /** 加载所有的应用列表 */
  loadAppList () {
    ApplicationApi.hasPermission()
      .then((res) => {
        this.appList = res.data || []
      })
      .catch((err) => {
        this.$Notice.error({
          title: '错误',
          desc: '应用列表加载失败:' + err.message
        })
      })
  }

  /** 加载当前拥有的服务器列表 */
  loadCurPcServerList () {
    TeamResourcePcServerApi.getItemByResourceId(this.formData.id).then((res) => {
      this.curPcServerList = res.data || []
    })
  }

  /** 加载当前拥有的应用列表 */
  loadCurAppList () {
    TeamResourceAppApi.getItemByResourceId(this.formData.id).then((res) => {
      this.curAppList = res.data || []
    })
  }

  /**
   * 当前编辑权限
   */
  get canEdit () {
    const user = getterUserInfo()
    return user.canOperateResource
  }

  /** 增加服务器 */
  addPcServer () {
    showPcServerSelectorModal(this.pcServerList).then((res) => {
      // 需要新增的数组，过滤掉已有数据
      const needAddPcServer = res.filter(
        (item) =>
          this.curPcServerList.findIndex((e) => e.pcServerId === item.pcServerId) < 0
      )
      // 在新增情况下
      if (this.formMode === 'new') {
        this.curPcServerList.push(...needAddPcServer)
      } else {
        // 在修改情况下
        needAddPcServer.forEach((item) => {
          item.teamResourceId = this.formData.id
        })
        TeamResourcePcServerApi.insertItems(needAddPcServer)
          .then(() => {
            this.loadCurPcServerList()
            this.$Message.success('添加成功')
          })
          .catch((err) => {
            this.$Message.error('添加失败: ' + err)
          })
      }
    })
  }

  /** 删除服务器 */
  deletePcServer (id: number) {
    // 在新增情况下
    if (this.formMode === 'new') {
      this.curPcServerList = this.curPcServerList.filter(
        (item) => item.pcServerId !== id
      )
    } else {
      // 在修改情况下
      TeamResourcePcServerApi.deleteItemByPcIds(this.formData.id, [id])
        .then(() => {
          this.loadCurPcServerList()
          this.$Message.success('删除成功')
        })
        .catch((err) => {
          this.$Message.error('删除失败: ' + err)
        })
    }
  }

  /** 增加应用 */
  addApp () {
    showAppSelectorModal(this.appList).then((res) => {
      // 需要新增的数组，过滤掉已有数据
      const needAddApp = res.filter(
        (item) =>
          this.curAppList.findIndex((e) => e.applicationId === item.applicationId) < 0
      )
      // 在新增情况下
      if (this.formMode === 'new') {
        this.curAppList.push(...needAddApp)
      } else {
        // 在修改情况下
        needAddApp.forEach((item) => {
          item.teamResourceId = this.formData.id
        })
        TeamResourceAppApi.insertItems(needAddApp)
          .then(() => {
            this.loadCurAppList()
            this.$Message.success('添加成功')
          })
          .catch((err) => {
            this.$Message.error('添加失败: ' + err)
          })
      }
    })
  }

  /** 删除应用 */
  deleteApp (id: number) {
    // 在新增情况下
    if (this.formMode === 'new') {
      this.curAppList = this.curAppList.filter((item) => item.applicationId !== id)
    } else {
      // 在修改情况下
      TeamResourceAppApi.deleteByApplicationId(this.formData.id, [id])
        .then(() => {
          this.loadCurAppList()
          this.$Message.success('删除成功')
        })
        .catch((err) => {
          this.$Message.error('删除失败: ' + err)
        })
    }
  }

  /* 加载指定id的表单数据 */
  onLoadFormData (id: number): Promise<TeamResource> {
    return new Promise<TeamResource>((resolve, reject) => {
      Promise.all([
        TeamResourceApi.getItemById(id),
        TeamResourcePcServerApi.getItemByResourceId(id),
        TeamResourceAppApi.getItemByResourceId(id)
      ]).then((res) => {
        this.curPcServerList = res[1].data || []
        this.curAppList = res[2].data || []
        resolve(res[0].data!)
      })
    })
  }

  /* 生成新增的表单数据 */
  onCreateNewFormData (): Promise<TeamResource> {
    return new Promise<TeamResource>((resolve, reject) => {
      resolve({
        id: 0,
        name: '',
        memo: '',
        teamId: 0,
        teamName: ''
      })
    })
  }

  doFormValidate (callback: (valid?: boolean | undefined) => void) {
    (this.$refs.form as Form).validate((valid) => callback(valid))
  }

  /* 执行新增数据保存 */
  onInsertFormData (): Promise<TeamResource> {
    return new Promise<TeamResource>((resolve, reject) => {
      TeamResourceApi.newInsertItem({
        teamResource: this.formData,
        pcServerList: this.curPcServerList,
        appList: this.curAppList
      })
        .then((response) => {
          this.setCurPageDataId(response.data?.id)
          this.formData.id = response.data?.id || 0
          resolve(response.data!)
        })
        .catch((err: Error) => reject(err))
    })
  }

  /* 保存修改数据 */
  onSaveFormData (): Promise<TeamResource> {
    return new Promise<TeamResource>((resolve, reject) => {
      TeamResourceApi.updateItem(this.formData)
        .then((response) => resolve(response.data!))
        .catch((err: Error) => reject(err))
    })
  }
}
