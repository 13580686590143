import { TeamResourceGroupAndUser } from '@/api/maintain/resource-management/team-resource-group-and-user'
import { TeamGroup } from '@/api/team-group'
import { User } from '@/api/user'
import { showModal } from '@/libs/modal-helper'
import modal from './add-user-or-group-modal.vue'

/**
  * 添加用户或用户组对话框
  * @returns
  */
export default function showAddUserOrGroupModal (userList: User[], groupList: TeamGroup[], resourceId: number, data?: TeamResourceGroupAndUser) {
  return new Promise<void>((resolve, reject) => {
    showModal<void>(modal, {
      props: {
        userList,
        groupList,
        resourceId,
        oriData: data
      }
    }, true, () => {
      resolve()
      return new Promise<void>((resolve, reject) => resolve())
    }, () => {
      reject(new Error('用户取消'))
    })
  })
}
