
import { Prop, Vue, Component } from 'vue-property-decorator'
import { BaseModal } from '@/libs/modal-helper'
import { PropType } from 'vue'
import { Application } from '@/api/maintain/application'

 /** 应用选择器组件 */
 @Component({
   name: 'AppSelectorModal',
   components: { }
 })
export default class AppSelectorModal extends Vue implements BaseModal {
  @Prop({
    type: Array as PropType<Application[]>,
    required: true
  })
  appList!: Application[]

  // 显示状态
  visiable = false
  app: number[] = []

  show (): void {
    this.visiable = true
  }

  close (): void {
    this.visiable = false
  }

  doSave (): void {
    this.$emit('onOk',
      this.appList
        .filter(item => this.app.findIndex((e) => e === item.id) >= 0)
        .map(item => {
          return {
            teamResourceId: 0,
            applicationId: item.id!,
            name: item.name,
            title: item.title || ''
          }
        }))
  }

  doCancel (): void {
    this.$emit('onCancel')
  }
}
