import { ToolBarCodeExportAll } from '@/frame/share/table-panel'

/// 表格基本配置
const options = {}
/// 导出列定义
const columns = [
  { type: 'checkbox', width: 45 },
  {
    field: 'userDisplayName',
    title: '名称',
    formatter: ({ row }: any) => {
      return row.userDisplayName || row.userGroupName || row.userLoginName
    }
  },
  {
    field: 'disable',
    title: '用户状态',
    formatter: ({ row }: any) => {
      if (typeof row.disable === 'boolean') {
        return row.disable ? '禁用' : '启用'
      }
      return ''
    },
    width: 80
  },
  { field: 'memberTypeName', title: '类型', width: 80, align: 'center' },
  { slot: 'operate_permission' },
  { width: 100, slot: 'col_action' }
]

/// 导出工具栏定义
const toolbarConfig = {
  buttons: [
    { code: 'insert', name: '新增', icon: 'md-add' },
    { code: 'delete', name: '直接删除', icon: 'fa fa-trash-o', status: 'error' },
    { code: ToolBarCodeExportAll, name: '全量导出', icon: 'vxe-icon--download', status: 'success' }
  ],
  refresh: true,
  export: true,
  print: true,
  zoom: true,
  custom: true,
  exportall: true
}
/**
 * 表格的打印配置
 */
const printConfig = {
  sheetName: '打印表格'
}

/**
 * 表格的导出配置
 */
const tableExport = {
// 默认选中类型
  type: 'xlsx',
  // 自定义类型
  types: ['xlsx', 'csv', 'html', 'xml', 'txt']
}

/// 导出配置
export default {
  options: options,
  columns: columns,
  toolbar: toolbarConfig,
  printConfig: printConfig,
  tableExport: tableExport
}
