
import { Prop, Component, Watch } from 'vue-property-decorator'
import tableConfig from './permission-config-table.config'
import { PagerQueryBean, PageListData } from '@/libs/http-request'
import BaseTablePanel from '@/frame/share/table-panel'
import _ from 'lodash'
import TablePanel from '@/frame/share/table-panel.vue'
import showAddUserOrGroupModal from './add-user-or-group-modal'
import TeamResourceGroupAndUserApi, {
  TeamResourceGroupAndUser
} from '@/api/maintain/resource-management/team-resource-group-and-user'
import TeamGroupApi, { TeamGroup } from '@/api/team-group'
import TeamUserApi, { TeamUse } from '@/api/team-user'

const defaultQuery = {
  page: 1,
  pageSize: 10,
  sorts: []
}
@Component({
  name: 'PermissionConfigTable',
  components: { TablePanel }
})
export default class PermissionConfigTable extends BaseTablePanel<any> {
  /** 资源id */
  @Prop({
    type: Number,
    required: true
  })
  resourceId!: number

  /** 表单当前状态 */
  @Prop({
    type: String,
    default: 'new'
  })
  formMode!: string

  // 当前状态
  loading = false
  /// 表格定义
  tableConfig = tableConfig
  /// 查询数据
  queryData: PagerQueryBean = _.cloneDeep({ ...defaultQuery })
  /// 选中的列
  selectItems: TeamResourceGroupAndUser[] = []
  /** 所有的用户组列表 */
  groupList: TeamGroup[] = []
  /** 所有的用户列表 */
  userList: TeamUse[] = []

  /**
   * 页面数据
   */
  pageData: PageListData<any> = {
    items: [],
    total: 0,
    pageSize: 10
  }

  created () {
    this.loadUserList()
    this.loadGroupList()
    this.doPullDataList()
  }

  @Watch('resourceId')
  onResourceId () {
    if (this.resourceId) {
      this.queryData = _.cloneDeep({
        ...defaultQuery,
        teamResourceId: this.resourceId
      })
      this.doPullDataList()
    }
  }

  /** 加载所有的用户列表 */
  loadUserList () {
    TeamUserApi.curTeamUserList()
      .then(res => {
        this.userList = res.data || []
      })
      .catch(err => {
        this.$Notice.error({
          title: '错误',
          desc: '用户列表加载失败:' + err.message
        })
      })
  }

  /** 加载所有的用户组列表 */
  loadGroupList () {
    TeamGroupApi.query({ page: 1, pageSize: 10000, sorts: [] })
      .then(res => {
        this.groupList = res.data?.items || []
      })
      .catch(err => {
        this.$Notice.error({
          title: '错误',
          desc: '用户组列表加载失败:' + err.message
        })
      })
  }

  /** 新增用户/用户组 */
  add () {
    showAddUserOrGroupModal(
      this.userList,
      this.groupList,
      this.resourceId
    ).then(() => {
      this.doPullDataList()
      this.$Message.success('添加成功')
    })
  }

  /** 查看/修改 用户/用户组 */
  view (data: TeamResourceGroupAndUser) {
    showAddUserOrGroupModal(
      this.userList,
      this.groupList,
      this.resourceId,
      data
    ).then(() => {
      this.doPullDataList()
      this.$Message.success('添加成功')
    })
  }

  /** 根据id删除 */
  deleteItemById (data: any) {
    if (this.pageData.items.length === 1) {
      this.$Message.error('当前为本资源的最后一个用户，如果删除了对应的资源将没有人可以管理')
      return
    }
    this.$Modal.confirm({
      title: '是否删除？',
      content: `是否删除名称 ${data.userDisplayName} 项`,
      onOk: () => {
        TeamResourceGroupAndUserApi.deleteItemByIds([data.id!])
          .then(() => {
            this.$Message.info('删除成功')
            this.selectItems = []
            this.doPullDataList()
          })
          .catch(err => {
            this.$Message.error({
              content: err.message
            })
          })
      }
    })
  }

  /** 根据id数组批量删除 */
  deleteItemByIds () {
    if (this.selectItems.length === 0) {
      this.$Message.error('请选择要删除的数据!')
      return
    }
    this.$Modal.confirm({
      title: '是否删除？',
      content: `是否删除 ${this.selectItems.length} 项数据`,
      onOk: () => {
        TeamResourceGroupAndUserApi.deleteItemByIds(
          this.selectItems.map(e => e.id!)
        )
          .then(() => {
            this.$Message.info('删除成功')
            this.selectItems = []
            this.doPullDataList()
          })
          .catch(err => {
            this.$Message.error({
              content: err.message
            })
          })
      }
    })
  }

  /**
   * 复选框点击事件
   */
  onSelectionChange (datas: Array<any>) {
    this.selectItems = datas
  }

  /**
   * 工具栏按钮事件
   */
  doToolBarButtonAction (code: string) {
    this.toolBarButtonAction(
      code,
      (this.$refs.tablePanel as any).getTableObject()
    )
  }

  /**
   * 页面数据拉取
   */
  doPullDataList (pageInfo?: any) {
    if (!this.resourceId) return
    pageInfo = pageInfo || { curPage: 1, pageSize: 10 }

    const that = this

    this.pullDataList(pageInfo).then(response => {
      that.pageData = response
    })
  }

  /**
   * 从服务器拉取数据
   */
  onPullDataListFromServer (pagerQueryBean: PagerQueryBean) {
    if (!this.resourceId) {
      return Promise.reject(new Error('资源id不能为空'))
    }
    return TeamResourceGroupAndUserApi.queryView({
      ...pagerQueryBean,
      teamResourceId: this.resourceId
    })
  }

  async handleSetRight (id: number, right: string, value: boolean) {
    try {
      if (value) await TeamResourceGroupAndUserApi.setRightById(id, 'canView', true)
      await TeamResourceGroupAndUserApi.setRightById(id, right, value)
      this.doPullDataList()
    } catch (error) {
      this.$Message.error({
        content: `修改权限失败: ${(error as any)?.message || error}`
      })
      console.error(error)
    }
  }
}
