import DefaultApiService, { BaseEntity } from '@/libs/api-request'

/** 资源的服务器列表 */
export interface TeamResourcePcServer extends BaseEntity {
  /** 团队资源id */
  teamResourceId: number;
  /** 服务器id */
  pcServerId: number;
  /** 服务器名称 */
  name: string;
  /** 服务器标ip地址 */
  ipAddress: string;
}

/** 资源的服务器列表Api接口 */
class TeamResourcePcServerApi extends DefaultApiService<TeamResourcePcServer> {
  constructor () {
    super('/api/maintain/team-resource-pc-server')
  }

  /**
   * 删除id指定的数据
   * @param id 记录主键
   */
  public deleteItemById (id: number) {
    return this.request(`deleteById/${id}`, undefined, 'DELETE')
  }

  /**
   * 根据resourceId查询服务器
   * @param id
   * @returns
   */
  public getItemByResourceId (id: number) {
    return this.requestList(`getItemByResourceId/${id}`, undefined, 'GET')
  }

  /**
   * 插入多条数据
   * @param data
   * @returns
   */
  public insertItems (data: TeamResourcePcServer[]) {
    return this.request('insertItems', data, 'PUT')
  }

  /** 根据pcid删除多条记录 */
  public deleteItemByPcIds (resourceId: number, pcIds: number[]) {
    return this.request(`pc/${resourceId}`, pcIds, 'DELETE')
  }
}

export default new TeamResourcePcServerApi()
